import Image from "next/image";
import Link from "next/link";
import React from "react";
import { useState, useEffect } from "react";
import {
  topInfluencers,
  topBrands,
  topCauses,
} from "../../data/collection_data";
import { featureBrands } from "../../services/landing";
import { getAllBrands } from "../../services/campaign";
import Topbrands from "./Topbrands";
import TopCauses from "./TopCauses";
import { uniqueFilter } from "../../utils/filters";
export const baseURL = `${process.env.BASE_URL}/`;

const Collection_category = ({ bgWhite = false }) => {
  const [loadingInfluencers, setLoadingInfluencers] = useState(true);
  const [getInfluencerData, setInfluencerData] = useState();
  const [loadingBrands, setLoadingBrands] = useState(true);
  const [getBrandData, setBrandData] = useState();

  const [getCauseData, setCauseData] = useState();

  //api
  const [campaign, setCampaign] = useState([]);
  const [filter, setFilter] = useState({
    category: [],
    name: [],
  });
  const [filteredCampaigns, setFilteredCampaigns] = useState([]);

  const [filterValue, setFilterValue] = useState({
    name: "",
    category: "",
    country: "",
  });

  useEffect(() => {
    const fetchApi = () => {
      getAllBrands()
        .then((result) => {
          setCampaign(result.brandsList);

          console.log(campaign);
          setFilter({
            ...filter,
            category: uniqueFilter(
              result.brandsList.map((item) => ({
                value: item.category.toLowerCase(),
                label: item.category.toLowerCase(),
              }))
            ),
            name: uniqueFilter(
              result.brandsList.map((item) => ({
                value: item.name.toLowerCase(),
                label: item.name.toLowerCase(),
              }))
            ),
          });
        })
        .catch((error) => console.log("error", error));
    };
    fetchApi();
    // Simulate data loading delay
    const delay = setTimeout(() => {
      setLoadingInfluencers(false);
    }, 1000);

    // Clean up the timeout on component unmount
    return () => clearTimeout(delay);
  }, []);

  return (
    <div>
      {/* <!-- Today's Drops / Sellers / Buyers --> */}
      <section className="py-05 relative">
        {bgWhite && (
          <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
            <Image
              width={1519}
              height={773}
              priority
              src="/images/gradient_light.jpg"
              alt="gradient"
              className="h-full w-full object-cover"
            />
          </picture>
        )}
        <div className="container">
          <div className="flex flex-col space-y-5 lg:flex-row lg:space-y-0 lg:space-x-7">
            {loadingInfluencers ? (
              <div className="dark:bg-jacarta-800 bg-light-base rounded-2.5xl p-12 lg:w-1/3">
                <h2 className="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
                  Top Influencers
                </h2>

                <div className="flex flex-col space-y-5">
                  <p></p>
                </div>
                <Link
                  href="/404"
                  className="text-accent mt-8 block text-center text-sm font-bold tracking-tight"
                >
                  View All
                </Link>
              </div>
            ) : (
              <>
                {topInfluencers.map(({ parentId, name, collections }) => {
                  return (
                    <div
                      className="dark:bg-jacarta-800 bg-light-base rounded-2.5xl p-12 lg:w-1/3"
                      key={parentId}
                    >
                      <h2 className="mb-8 block font-display text-xl font-medium group-hover:text-accent dark:text-white  transition-colors text-center">
                        {name}
                      </h2>

                      <div className="flex flex-col space-y-5">
                        {collections.map((item) => {
                          const { id, image, title, icon, amount, postTime } =
                            item;
                          const itemLink = image
                            .split("/")
                            .slice(-1)
                            .toString()
                            .replace(".jpg", "")
                            .replace(".gif", "");

                          return (
                            <div
                              key={id}
                              className="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent"
                            >
                              <figure className="mr-4 shrink-0">
                                <Link
                                  href={`/influencer/${id}`}
                                  className="relative block"
                                >
                                  <Image
                                    width={48}
                                    height={48}
                                    src={image}
                                    alt={title}
                                    className="rounded-2lg h-12 w-12"
                                  />
                                  <div className="dark:border-jacarta-600 bg-jacarta-700 absolute -left-3 top-1/2 flex h-6 w-6 -translate-y-2/4 items-center justify-center rounded-full border-2 border-white text-xs text-white">
                                    {id}
                                  </div>
                                  {icon && (
                                    <div
                                      className="dark:border-jacarta-600 bg-green absolute -left-3 top-[60%] flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
                                      data-tippy-content="Verified Collection"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        className="h-[.875rem] w-[.875rem] fill-white"
                                      >
                                        <path
                                          fill="none"
                                          d="M0 0h24v24H0z"
                                        ></path>
                                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                                      </svg>
                                    </div>
                                  )}
                                </Link>
                              </figure>
                              <div>
                                <Link
                                  href={`/influencer/${id}`}
                                  className="block"
                                >
                                  <span className="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
                                    {title}
                                  </span>
                                </Link>
                                <span className="dark:text-jacarta-300 text-sm">
                                  {amount}
                                </span>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <Link
                        href="/404"
                        className="text-accent mt-8 block text-center text-sm font-bold tracking-tight"
                      >
                        View All
                      </Link>
                    </div>
                  );
                })}
              </>
            )}
            <Topbrands />
            <TopCauses />
          </div>
        </div>
      </section>
      {/* <!-- end today's drops / sellers / buyers --> */}
    </div>
  );
};

export default Collection_category;
