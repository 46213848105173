import React from "react";

import Image from "next/image";

const OfferTab = ({ userActivities }) => {
  return (
    <>
      {/* <!-- Offers --> */}
      <div
        className="tab-pane fade show active overflow-x-auto "
        id="offers"
        role="tabpanel"
        aria-labelledby="offers-tab"
      >
        <div
          role="table"
          className="scrollbar-custom cusTom dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 grid max-h-72 w-full grid-cols-5 overflow-y-auto rounded-lg rounded-tl-none border bg-white text-sm dark:text-white"
        >
          <div className="contents" role="row">
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                From
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                To
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Event
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Amount
              </span>
            </div>
            <div
              className="dark:bg-jacarta-600 bg-light-base sticky top-0 py-2 px-4"
              role="columnheader"
            >
              <span className="text-jacarta-700 dark:text-jacarta-100 w-full overflow-hidden text-ellipsis">
                Asset
              </span>
            </div>
          </div>

          {userActivities?.map((item, i) => (
            <div
              className="contents  cusTom min-w-[900px] !min-h-[900px] overflow-x-auto"
              role="row"
              key={i}
            >
              <div
                className="dark:border-jacarta-600 border-jacarta-100 flex items-center whitespace-nowrap border-t py-4 px-4"
                role="cell"
              >
                <span className="-ml-1" data-tippy-content="ETH">
                  <svg className="icon mr-1 h-4 w-4">
                    <use xlinkHref="/icons.svg#icon-ETH"></use>
                  </svg>
                </span>
                <span className="text-green text-sm font-medium tracking-tight">
                  {item?.from?.username}
                </span>
              </div>
              <div
                className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                role="cell"
              >
                {item?.to?.username}
              </div>
              <div
                className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                role="cell"
              >
                {item?.verb}
              </div>
              <div
                className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                role="cell"
              >
                {item.amount}
              </div>

              <div
                className="dark:border-jacarta-600 border-jacarta-100 flex items-center border-t py-4 px-4"
                role="cell"
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "1rem" }}
                >
                  <Image
                    alt="avatar"
                    height={30}
                    width={30}
                    src={`${process.env.NEXT_PUBLIC_BASE_URL_DEV}/${item?.asset?.imageUrl}`}
                    loading="lazy"
                    style={{ borderRadius: "50%" }}
                  />
                  <span>{item?.asset?.name}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OfferTab;
